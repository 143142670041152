import {css} from "@emotion/react";

import {ChevronDownIcon} from "@pg-design/icons-module";

interface IProps {
    isDown: boolean;
}

export const SearchDropdownArrow = (props: IProps) => {
    return (
        <div css={props.isDown ? rotatedStyle : rotatedStyleReverse}>
            <ChevronDownIcon size="2" wrapperSize="2" wrapperColor="transparent" />
        </div>
    );
};

const rotatedStyle = css`
    transform: rotate(-180deg);
    transition: ease-in-out 0.2s;
`;
const rotatedStyleReverse = css`
    transform: rotate(0deg);
    transition: ease-in-out 0.2s;
`;
